export const PRISMIC_EXPERIMENT_SLICE_TYPE = 'prismic_experiment' as const;
export const FEATURE_EXPERIMENT_SLICE_TYPE = 'feature_experiment' as const;

export const EXPERIMENT_SLICE_TYPES = [
  PRISMIC_EXPERIMENT_SLICE_TYPE,
  FEATURE_EXPERIMENT_SLICE_TYPE,
] as const;

export enum EXPERIMENTS {
  checkoutPaymentsStripe = 'checkout_payments_stripe',
}

export enum STATSIG_EXPERIMENTS {
  stripeIntegration = 'exp18_stripe_integration',
  cookieConsent = 'exp21_cookie_consent',
}

export enum STATSIG_FEATURE_GATES {
  stripeIntegration = 'exp18_stripe_integration_gate',
  defaultPaymentGatewayStripe = 'exp18_default_payment_gateway_stripe',
}

export enum STATSIG_EVENTS {
  viewLandingPage = 'view_landing_page',
  startQuiz = 'start_quiz',
  viewCheckoutPage = 'view_checkout_page',
  beginCheckout = 'begin_checkout',
  changePlan = 'change_plan',
  changePaymentMethod = 'change_payment_method',
  purchase = 'purchase',
  viewSpecialCheckoutPage = 'view_special_checkout_page',
  beginSpecialCheckout = 'begin_special_checkout',
  changeSpecialPlan = 'change_special_plan',
  changeSpecialPaymentMethod = 'change_special_payment_method',
  specialPurchase = 'special_purchase',
}
